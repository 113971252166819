<template>
  <app-list-view
    server-side
    app="customer_company"
    model="customercompanystorage"
    api-url="customer-company/customer_company_storage/"
    :title="$t('menu.customerCompanyStorage')"
    :headers="headers"
    hide-create
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView.vue'

export default {
  name: 'customerCompanyStorageList',
  components: { AppListView },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.customerCompany'),
          value: 'customer_company_id.name'
        },
        {
          text: this.$t('fields.date'),
          value: 'date'
        },
        {
          text: this.$t('fields.usableUserCount'),
          value: 'usable_user_count'
        },
        {
          text: this.$t('fields.totalStorage'),
          value: 'total_available_storage_gb'
        },
        {
          text: this.$t('fields.data'),
          value: 'db_storage_gb'
        },
        {
          text: this.$t('fields.file'),
          value: 'file_storage_gb'
        },
        {
          text: this.$t('fields.totalUsed'),
          value: 'total_used_storage_gb'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'customerCompanyStorage'
    })
  }
}
</script>
